<template>
  <div class="theNewAddress">
    <van-address-edit
        :area-list="areaList"
        :address-info="addressInfo"
        show-set-default
        show-delete
        :is-saving="saveLoading"
        :is-deleting="deleteLoading"
        :area-columns-placeholder="['请选择', '请选择', '请选择']"
        @save="onSave"
        @delete="onDelete"
    />
  </div>
</template>

<script>
import {AddressEdit, Toast} from 'vant';
import { areaList } from '@vant/area-data';
import leaseApi from "../../../utils/leaseApi";
export default {
  name: "theNewAddress",
  components:{
    AddressEdit
  },
  data() {
    return {
      areaList,
      addressInfo:{},
      saveLoading : false,
      deleteLoading : false,
      type : ''
    };
  },
  created() {
    let type = this.$route.query.type
    if (type === 'add'){
      this.type = 'add'
    }else {
      this.type = 'edit'
      this.addressInfo = this.$route.query
      this.addressInfo.addressDetail = this.$route.query.detailedAddress
      let county = this.addressInfo.county
      for (let key in this.areaList.county_list){
        if (this.areaList.county_list[key] === county){
          this.addressInfo.areaCode = key
          return;
        }
      }
    }
  },
  methods: {
    async onSave(content) {
      this.saveLoading = true
      content.phone = content.tel
      content.recipients = content.name
      content.detailedAddress = content.addressDetail
      content.defaultStatus = content.isDefault ? 1 : 0
      if (this.type === 'add'){
        const { msg } = await leaseApi.saveSite(content)
        Toast(msg)
      }else {
        const { msg } = await leaseApi.editSite(content)
        Toast(msg)
      }
      this.saveLoading = false
      let _this = this
      setTimeout(function () {
          if (_this.$store.state.addressShow){
            _this.$router.push({name: "deliveryAddress",query :{ type : "1"}})
          }else {
            _this.$router.push({name: "deliveryAddress"})
          }
      },1500)
    },
    async onDelete(content) {
      this.deleteLoading = true
      const {msg} = await leaseApi.deleteSite({id: content.id})
      Toast(msg)
      this.deleteLoading = false
      let _this = this
      setTimeout(function () {
        _this.$router.push({name: "deliveryAddress"})
      },1500)
    },
  },
}
</script>

<style lang="less" scoped>
/deep/.van-button--danger{
  height: 1.11rem;
  background: #FB884F;
  border-radius: 1rem;
  border: 0rem;
}
/deep/.van-field__label{
  font-weight: bold;
}
/deep/.van-cell__title{
  font-weight: bold;
  color: #333;
}
/deep/.van-switch--on{
  background-color: rgba(251, 136, 79, 1);
}
</style>
